<script>
import axios from "axios";

export default {
  name: "ApplicationUpdate",
  props: {
    applicationProp: null,
  },
  data() {
    return {
      application: null,
    };
  },
  mounted() {
    if (!this.application) {
      this.getApplicationById();
    } else {
      this.application = this.applicationProp;
    }
  },
  methods: {
    getApplicationById() {
      axios.get("applications/" + this.$route.params.id).then((res) => {
        this.application = res.data;
      });
    },
  },
};
</script>

<template>
  <div v-if="application">
    <div class="has-text-centered">
      <h1 class="is-size-4">Заявление №{{ application.id }}</h1>
      <h2 class="is-size-6">
        {{ localize(application.category, "title") }}
      </h2>
      <p>Дата подачи: {{ application.sent_date | formatDate }}</p>
      <p>Статус заявления: {{ $t(application.status) }}</p>
    </div>
    <div class="columns is-centered">
      <div class="column is-8">
        <b-table :data="application.documents">
          <b-table-column field="title" v-slot="props">
            <a
              class="is-size-6"
              :href="props.row.uploaded_file"
              target="_blank"
            >
              {{ localize(props.row.document_type, "title") }}
            </a>
          </b-table-column>
          <b-table-column field="status" v-slot="props" width="200px">
            <div
              class="is-flex is-align-items-center"
              :set="
                (statusClass = getCSSClassAndIconForDocumentStatus(
                  props.row.status
                ))
              "
            >
              <b-icon
                class="mr-4"
                :type="statusClass[1]"
                :icon="statusClass[2]"
                size="is-medium"
              >
              </b-icon>
              <span :class="statusClass[0]">
                {{ $t(props.row.status) }}
              </span>
            </div>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>
